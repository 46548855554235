import Http from '../utils/axios'
const rt = '/api'
const user = '/user'
export default {
    /** 获取组织树结构 */
    queryOrganizationTree(params) {
        return Http.post(`${rt}${user}/v1/e/organization/buildOrganizationTree`,params, false)
    },
    // 新增和编辑组织
    saveOrganization(params) {
        return Http.post(`${rt}${user}/v1/e/organization/saveOrUpdate`,params, false)
    },
    // 查询组织详情
    queryOrganizationDetail(params) {
        return Http.get(`${rt}${user}/v1/e/organization/details?id=${params.id}`,'', false)
    },
    // 查询岗位列表
    queryStationList(params) {
        return Http.post(`${rt}${user}/v1/e/station/getStationPageByOrg`,params, true)
    },
    // 获取岗位列表（新接口）
    getStationListByOrg(params){
        return Http.get(`${rt}${user}/v1/e/station/getStationListByOrg`,params, false)
    },
    // 新增和编辑岗位
    saveStation(params) {
        return Http.post(`${rt}${user}/v1/e/station/addStation`,params, false)
    },
    // 获取岗位详情
    queryStationDetail(params) {
        return Http.get(`${rt}${user}/v1/e/station/getStationDetail`,params, false)
    },
    // 查询组织下用户列表
    queryUserList(params) {
        return Http.post(`${rt}${user}/v1/e/station/getUserPageByOrg`,params, true)
    },
    // 查询所有岗位
    queryStationListAll(params) {
        return Http.post(`${rt}${user}/v1/e/station/getStationList`,params, false)
    },
    // 组织树上下移动
    setSort(params) {
        return Http.post(`${rt}${user}/v1/e/organization/moveSort`,params, false)
    },
    /*
    ***  获取组织树
    */
    queryOrganizationJobTree(params){
        return Http.post(`${rt}${user}/v1/e/organization/buildOrganizationUserStationTree`,params, false)
    },
    
}