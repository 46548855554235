<template>
  <div>
    <rs-org-select
      v-model="orgSysCode"
      :label="label"
      nodeKey="orgSysCode"
      :treeData="treeParams.data"
      :props="treeParams.props"
      @change="orgSelectChange"
      ref="treeSelect"
    ></rs-org-select>
  </div>
</template>

<script>
import Api from '@/api/organizationApi'
export default {
  name: 'orgSelect',
  comments: [],
  props: {
    organizationSysCode: {
      type: String,
      default: ''
    },
    orgSelectName: {
      type: String,
      default: ''
    }
  },
  watch: {
    organizationSysCode(newValue, oldValue) {
      setTimeout(() => {
        this.orgSysCode = newValue
      }, 300)
    }
  },
  computed: {
    label() {
      return this.orgSelectName
    }
  },
  data() {
    return {
      orgSysCode: '',
      treeParams: {
        clickParent: true,
        filterable: true,
        'check-strictly': true,
        'default-expand-all': true,
        'expand-on-click-node': false,
        // 'check-on-click-node': true,
        'highlight-current': true,
        // 'show-checkbox': true,
        data: [],
        props: {
          children: 'children',
          label: 'orgName',
          disabled: 'disabled',
          value: 'orgSysCode'
        }
      }
    }
  },
  methods: {
    initTree(treeData) {
      let params = {}
      let data = []
      Api.queryOrganizationTree(params)
        .then((res) => {
          data = res.data.data ? res.data.data : []
          this.treeParams.data = []
          data.forEach((element) => {
            this.treeParams.data.push(this.handleMapTree(element))
          })
          this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data)
        })
        .catch((err) => {})
    },
    // 对树形数据进行处理disabled
    handleMapTree(el) {
      const haveChildren = Array.isArray(el.children) && el.children.length > 0
      return {
        disabled: el.status ? false : true,
        children: { ...el },
        companyCode: el.companyCode,
        id: el.id,
        label: el.orgName,
        value: el.orgSysCode,
        orgName: el.orgName,
        orgNameFullPath: el.orgNameFullPath,
        orgShowCode: el.orgShowCode,
        orgSysCode: el.orgSysCode,
        orgSysCodeFullPath: el.orgSysCodeFullPath,
        parentOrgSysCode: el.parentOrgSysCode,
        remark: el.remark,
        sort: el.sort,
        status: el.status,
        children: haveChildren ? el.children.map((i) => this.handleMapTree(i)) : null
      }
    },
    orgSelectChange(val) {
      this.$emit('orgSelectChange', val)
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.initTree()
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
</style>