var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("rs-org-select", {
        ref: "treeSelect",
        attrs: {
          label: _vm.label,
          nodeKey: "orgSysCode",
          treeData: _vm.treeParams.data,
          props: _vm.treeParams.props,
        },
        on: { change: _vm.orgSelectChange },
        model: {
          value: _vm.orgSysCode,
          callback: function ($$v) {
            _vm.orgSysCode = $$v
          },
          expression: "orgSysCode",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }